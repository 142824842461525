import React from 'react';
import { graphql, Link } from "gatsby";
import { GatsbyImage } from 'gatsby-plugin-image';

import fallbackThumbnail from '../../assets/main-background.png';

export function transformBlogPost(post) {
    return {
        ...post,
        image: post.thumbnail?.gatsbyImageData || post.content.references[0]?.gatsbyImageData,
        category: post.category || 'Blog',
    }
}

const BlogPage = ({ data }) => {
    const postData = data.allContentfulBlogPost.nodes
        .map(transformBlogPost);

    const featuredPost = postData.splice(0, 1)[0];

    return (<>
        <div className="w-full">
            <header>
                <h1>Blog</h1>
            </header>

            <div className="w-full relative sm:mb-8 md:mb-14"><Link to={featuredPost.id}>
                <div className="gradient-to-b-wrapper rounded-3xl relative">
                    {featuredPost.image
                        ? <GatsbyImage image={featuredPost.image} className="rounded-3xl w-full p-px max-h-1/2 object-cover" alt={featuredPost.title} />
                        : <img src={fallbackThumbnail} className="rounded-3xl w-full p-px max-h-1/2 object-cover" alt={featuredPost.title} />
                    }
                    <div className="absolute top-px left-px bottom-px right-px bg-indigo-900 bg-opacity-5 rounded-3xl" />
                </div>
                <div className="mt-6 sm:absolute bottom-8 right-8 left-10">
                    <div className="w-full flex justify-between sm:justify-start mb-4 items-center font-medium text-xs">
                        <div className="text-purple-500 leading-5 px-2 uppercase bg-indigo-900 bg-opacity-75 rounded-2xl border-2 border-indigo-900 mr-4">{featuredPost.category}</div>
                        <div className="text-ka-dim uppercase">{featuredPost.createdAt}</div>
                    </div>
                    <h2 className="overflow-ellipsis text-xl sm:text-3xl">{featuredPost.title}</h2>
                </div>
            </Link></div>

            <div className="grid grid-cols-6 sm:gap-6 mb-8 sm:mb-0">
                {postData.map(post =>
                    <div className="col-span-6 sm:col-span-3 lg:col-span-2" key={post.id}>
                        <div className="w-full block bg-indigo-500 py-px my-8 sm:hidden opacity-25"></div>
                        <Link to={post.id} className="sm:mb-8 flex items-center sm:flex-wrap">
                            <div className="w-36 sm:w-full flex-shrink-0 gradient-to-b-wrapper rounded-3xl sm:mb-6">
                                {post.image
                                    ? <GatsbyImage image={post.image} className="rounded-3xl p-px object-cover h-36 sm:h-64 w-36 sm:w-full" alt={post.title} />
                                    : <img src={fallbackThumbnail} className="rounded-3xl p-px object-cover h-36 sm:h-64 w-36 sm:w-full" alt={post.title} />
                                }
                            </div>
                            <div className="flex flex-wrap items-center flex-shrink sm:w-full pl-4 sm:pl-0">
                                <div className="text-purple-500 leading-5 px-2 bg-indigo-900 bg-opacity-75 rounded-2xl border-2 border-indigo-900 mr-4 font-medium text-xs uppercase order-1">{post.category}</div>
                                <div className="text-ka-dim uppercase font-medium text-xs order-4 md:order-2">{post.createdAt}</div>
                                <h3 className="text-row text-row--2 text-lg sm:text-2xl w-full mb-4 sm:mb-0 mt-4 order-3">{post.title}</h3>
                            </div>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    </>)
};

export const query = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(sort: { fields: createdAt, order: DESC }) {
      nodes {
        id
        title
        category
        createdAt(fromNow: true)
        thumbnail {
          gatsbyImageData
        }
        content {
          references {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default BlogPage;
