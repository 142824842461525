import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
export default ({ url, className, iconClassName }) => {
  return (
    <a
      href={`https://www.facebook.com/sharer.php?u=${escape(url)}`}
      className={className}
      target="_blank"
    >
      <FontAwesomeIcon className={`text-ka-cyan ${iconClassName}`} icon={faFacebook} />
    </a>
  );
};
