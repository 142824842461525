import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { transformBlogPost } from ".";
import * as styles from "./blog.module.scss";
import TwitterShare from "../../components/socials/TwitterShare";
import FacebookShare from "../../components/socials/FacebookShare";
import Seo from "../../components/seo";

const BlogPostPage = ({ data }) => {
  const post = transformBlogPost(data.contentfulBlogPost);
  const content = post.content;
  const document = JSON.parse(content.raw);

  const [shareUrl, setShareUrl] = useState('');
  useEffect(() => {
    setShareUrl(window.location.href);
  });

  return (
    <>
      <header className="flex flex-col items-center">
        <div
          className="header-bg-image"
          style={{
            "--header-bg-image": `url("${post.thumbnail?.fixed.src}")`,
          }}
        />
        <h1 className="order-2 sm:text-center pr-0 sm:text-4xl md:text-5xl" style={{ maxWidth: 800 }}>
          {post.title}
        </h1>
        <div
          className="order-3 sm:order-1
          w-full mt-5 sm:mt-0
          flex justify-between sm:justify-center items-center mb-6"
        >
          <div className="text-purple-500 leading-5 px-2 bg-indigo-900 bg-opacity-75 rounded-2xl border-2 border-ka-dim-10 mr-4 font-medium text-xs uppercase">
            {post.category}
          </div>
          <div className="text-ka-dim uppercase font-medium text-xs">
            {post.createdAt}
          </div>
        </div>
      </header>
      <Seo
        title={post.title}
        description={post.excerpt}
        image={(post.thumbnail || content.references[0])?.ogSized.src}
        article={{
          publishedTime: post.publishedTime,
          modifiedTime: post.modifiedTime,
        }}
      />

      <div className="mx-auto blogpost" style={{ maxWidth: 800 }}>
        {documentToReactComponents(document, {
          renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
              const refId = node.data.target.sys.id;

              const { gatsbyImageData, description } = content.references.find(
                (ref) => ref.id === refId
              );
              if (!gatsbyImageData) {
                // asset is not an image
                return null;
              } //imgClassName="object-cover overflow-hidden"
              return (
                <div className="flex flex-col content-center items-center">
                  <div className="mt-6 mb-2 gradient-to-b-wrapper rounded-xl">
                    <GatsbyImage className="rounded-xl" image={gatsbyImageData} alt={description} />
                  </div>
                  <div className="italic">{description}</div>
                </div>
              );
            },
            [BLOCKS.HEADING_1]: (_, children) => {
              return <h1 className="mt-10">{children}</h1>;
            },
            [BLOCKS.HEADING_2]: (_, children) => {
              return <h2 className="mt-8">{children}</h2>;
            },
            [BLOCKS.HEADING_3]: (_, children) => {
              return <h3 className="mt-6">{children}</h3>;
            },
            [BLOCKS.HEADING_4]: (_, children) => {
              return <h4 className="mt-6">{children}</h4>;
            },
            [BLOCKS.HEADING_5]: (_, children) => {
              return <h5 className="mt-6">{children}</h5>;
            },
            [BLOCKS.HEADING_6]: (_, children) => {
              return <h6 className="mt-6">{children}</h6>;
            },
            [BLOCKS.PARAGRAPH]: (_, children) => {
              return <p className="mt-6">{children}</p>;
            },
            [BLOCKS.OL_LIST]: (_, children) => {
              return <ol className={`list-disc ${styles.list}`}>{children}</ol>;
            },
            [BLOCKS.IL_LIST]: (_, children) => {
              return (
                <ul className={`list-decimal ${styles.list}`}>{children}</ul>
              );
            },
            [BLOCKS.QUOTE]: (_, children) => {
              return (
                <blockquote
                  className={`flex flex-wrap flex-col border-l-8 italic border-gray-400 px-4 py-3 ${styles.quote}`}
                >
                  {children}
                </blockquote>
              );
            },
            [INLINES.HYPERLINK]: (node, children, b) => {
              return (
                <a
                  href={node?.data?.uri}
                  target="_blank"
                >
                  {children}
                </a>
              );
            },
          },
        })}

        <hr className="mt-10 opacity-20" />
        <div className="flex row justify-between items-center h-14">
          <span className="opacity-50 text-xs uppercase">Share it on</span>
          <div className="flex items-center">
            <FacebookShare iconClassName="h-5 w-5 mx-2" url={shareUrl}/>
            <TwitterShare iconClassName="h-5 w-5 mx-2" url={shareUrl}/>
          </div>
        </div>
        <hr className="mb-14 opacity-20" />
      </div>
    </>
  );
};

export const query = graphql`
  query BlogPostQuery($id: String) {
    contentfulBlogPost(id: { eq: $id }) {
      id
      contentful_id
      __typename
      title
      category
      excerpt(limit: 160)
      createdAt(fromNow: true)
      publishedTime: createdAt
      modifiedTime: updatedAt
      thumbnail {
        fixed(height: 800) {
          src
        }
        ogSized: resize(width: 1200, height: 630, quality: 100) {
          src
        }
      }
      # This is the rich text field
      content {
        raw
        references {
          ... on ContentfulAsset {
            id: contentful_id
            __typename
            gatsbyImageData
            title
            description
            ogSized: resize(width: 1200, height: 630, quality: 100) {
              src
            }
          }
        }
      }
    }
  }
`;

export default BlogPostPage;
